// import searchTranslates from '../section-projects/search-i18n';
import reliabilityIndexTranslates from 'src-sites/bild/section-project/reliability-index-i18n.json';

export default function () {
  return {
    // ...searchTranslates(),
    ...reliabilityIndexTranslates,

    overflight: {
      ru: 'Аэрооблёт',
      ua: 'Аерообліт'
    },

    newBuildingReliabilityIndex: {
      ru: 'Индекс надёжности',
      ua: 'Індекс надійності'
    },

    developerTopic: {
      ru: 'Застройщик',
      ua: 'Забудовник'
    },

    developer: {
      ru: 'Застройщик',
      ua: 'Забудовник'
    },
    plans: {
      ru: 'Планировки',
      ua: 'Планування'
    },
    domikCard: {
      ru: 'Карточка Домик',
      ua: 'Карточка Домік'
    },
    domikContacts: {
      ru: 'Контакты',
      ua: 'Контакти'
    },
    domikNews: {
      ru: 'Новости ЖК',
      ua: 'Новини ЖК'
    },
    forumDeveloper: {
      ru: 'Форум застройщика',
      ua: 'Форум забудовника'
    },
    lunCard: {
      ru: 'Карточка ЛУН',
      ua: 'Карточка ЛУН'
    },
    lcSite: {
      ru: 'Сайт ЖК',
      ua: 'Сайт ЖК'
    },
    developerYear: {
      ru: ' с\u00a0{{placeholder}}\u00a0года',
      ua: ' з\u00a0{{placeholder}}\u00a0року'
    },
    developerHouse: {
      ru: 'дом',
      ua: 'будинок'
    },
    developerHouseDeclination: {
      ru: 'дома',
      ua: 'будинки'
    },
    developerHouseMultiple: {
      ru: 'домов',
      ua: 'будинків'
    },
    developerInCount: {
      ru: 'в\u00a0{{placeholder}}\u00a0ЖК',
      ua: 'в\u00a0{{placeholder}}\u00a0ЖК'
    },
    developerBuild: {
      ru: 'строят',
      ua: 'будують'
    },
    developerHouseReady: {
      ru: 'готовый дом',
      ua: 'готовий будинок'
    },
    developerHouseReadyDeclination: {
      ru: 'готовых дома',
      ua: 'готових будинки'
    },
    developerHouseReadyMultiple: {
      ru: 'готовых домов',
      ua: 'готових будинків'
    },
    developerHouseReadyCT: {
      ru: 'готовый КП',
      ua: 'готове КМ'
    },
    developerHouseReadyCTDeclination: {
      ru: 'готовых КП',
      ua: 'готових КМ'
    },
    developerHouseReadyCTMultiple: {
      ru: 'готовых КП',
      ua: 'готових КМ'
    },
    developerLinkText: {
      ru: 'Сайт компании',
      ua: 'Сайт компанії'
    },

    moreDetails: {
      ru: 'Подробнее',
      ua: 'Детальніше'
    },
    hide: {
      ru: 'Скрыть',
      ua: 'Сховати'
    },

    developerH2: {
      ru: 'Проекты',
      ua: 'Проєкти'
    },
    developerLand: {
      ru: 'Вся Украина',
      ua: 'Вся Україна'
    },
    developerFilterAllProjects: {
      ru: 'Все проекты',
      ua: 'Всі проєкти'
    },
    developerImgAlt: {
      ru: 'Жилые комплексы {{placeholder}}',
      ua: 'Житлові комплекси {{placeholder}}'
    },
    developerLinkToSiteTitle: {
      ru: 'Сайт застройщика',
      ua: 'Сайт забудовника'
    },
    developerPhoneTitle: {
      ru: 'Телефон застройщика',
      ua: 'Телефон забудовника'
    },
    developerFoundedYearText: {
      ru: 'Год основания',
      ua: 'Рік заснування'
    },
    developerHouseBuilt: {
      ru: 'Дом строится',
      ua: 'Будинок будується'
    },
    developerHouseBuiltDeclination: {
      ru: 'Дома строятся',
      ua: 'Будинки будуються'
    },
    developerHouseBuiltMultiple: {
      ru: 'Домов строятся',
      ua: 'Будинків будуються'
    },
    propertyReady: {
      ru: 'Сдача{{placeholder}}',
      ua: 'Здача{{placeholder}}'
    },
    propertyReadyYear: {
      ru: '{{placeholder}}\u00a0г.',
      ua: '{{placeholder}}\u00a0р.'
    },
    textLC: {
      ru: 'ЖК',
      ua: 'ЖК'
    },
    priceBeginText: {
      ru: 'от',
      ua: 'від'
    },
    call: {
      ru: 'Позвонить\u00a0',
      ua: 'Дзвонити\u00a0'
    },
    connectConsult: {
      ru: 'Консультант',
      ua: 'Консультант'
    },
  };
}
