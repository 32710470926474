import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import Bem from 'bemHelper';
import { daysDifAndTime, difDays } from 'helpers/formatDate';
import { I18nHoc } from 'helpers/i18n';
import sensor from 'components/sensor';
import Link from 'components/link';
import Image, { Thumb } from 'components/image';
import Row, { Col } from 'components/row';
import Icon, { iconTypes } from 'components/icon';

import linksDomik from 'src-sites/domik/links';

import translates from './articles-i18n';

import 'styles/domik/blocks/_block-articles.scss';

const bemClasses = new Bem('viewArticle');
const bemClassesList = new Bem('listArticles');

@I18nHoc(translates())
class Article extends Component {
  static propTypes = {
    position: PropTypes.number,
    className: PropTypes.string,
    dataMods: PropTypes.string,
    device: PropTypes.string,
    data: PropTypes.shape({}),
    withImg: PropTypes.bool
  };

  static defaultProps = {
    withImg: false
  };

  render() {
    const {
      className, position, data, dataMods, withImg
      // , device
    } = this.props;
    const {
      image: img_main, name, comments, time_to_publish, id, landing_url
    } = data;
    const header = name;
    const image = typeof img_main === 'string' ? { id: img_main } : img_main;
    const imageArticle = { ...image, alt: header, title: header };
    const commentCount = get(comments, 'count') || '0';
    // const isPhone = device === 'phone';
    const builderProps = {
      title: header,
      url: landing_url
    };
    const commentsCount = get(comments, 'count');

    return (
      <div
        key={id}
        {...bemClasses(null, dataMods, className)}
      >
        <article {...bemClasses('content')}>
          {withImg &&
            <Row {...bemClasses('row', 'img')}>
              <Col {...bemClasses('col', 'img')} allPr={0}>
                <div {...bemClasses('imgContainer')}>
                  <div {...bemClasses('img')}>
                    <Link
                      {...bemClasses('link', 'fill')}
                      builder={linksDomik.sectionLanding}
                      builderProps={builderProps}
                      children={<span />}
                    />
                    <Thumb
                      {...bemClasses('imgPic')}
                      image={imageArticle}
                      full={position === 0}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          }
          <header {...bemClasses('title')}>
            <h3>
              <Link
                {...bemClasses('link', 'toArticle')}
                builder={linksDomik.sectionLanding}
                builderProps={builderProps}
              >
                <span {...bemClasses('linkContent')}>
                  <span {...bemClasses('text')} children={header} />
                </span>
              </Link>
            </h3>
          </header>
          <div {...bemClasses('item', 'time')}>
            <time
              {...bemClasses('time')}
              dateTime={time_to_publish}
              children={daysDifAndTime.call(this, time_to_publish)}
            />
          </div>
          {!!commentsCount &&
            <div {...bemClasses('item', 'commentCount')}>
              <div {...bemClasses('commentCount')}>
                <div {...bemClasses('btn', 'toComment')}>
                  <span {...bemClasses('btnContent')}>
                    <Icon {...bemClasses('icon')} type={iconTypes.commentRound} />
                    <span {...bemClasses('text')} children={commentCount} />
                  </span>
                </div>
              </div>
            </div>
          }
          <footer />
        </article>
      </div>
    );
  }
}

@sensor
export default class ListArticle extends Component {
  static propTypes = {
    className: PropTypes.string,
    dataMods: PropTypes.string,
    device: PropTypes.string,
    data: PropTypes.shape({})
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;

    this.state = {
      device: 'phone'
    };
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    const { device } = this.state;

    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  renderArticle(item, key, device, withImg) {
    return (
      <Article
        position={key}
        data={item}
        device={device}
        withImg={withImg}
        dataMods="tile"
      />
    );
  }

  render() {
    const { content } = this.props;

    if (!content || content.length === 0) return null;

    const { device } = this.state;

    return (
      <div {...bemClassesList()}>
        <div {...bemClassesList('first')}>
          {map(content, (item, key) => {
            if (key >= 3) return null;

            return this.renderArticle(item, key, device, true);
          })}
        </div>
        <div {...bemClassesList('other')}>
          {map(content, (item, key) => {
            if (key < 3) return null;

            return this.renderArticle(item, key, device);
          })}
        </div>
      </div>
    );
  }
}
