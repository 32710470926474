import formatDateTranslates from 'helpers/format-date-i18n.json';
import numDescTranslates from 'helpers/num-desc-i18n.json';
import lc from 'src-sites/forum/lc-i18n';
import configLabelsTranslates from '../section-project/config-labels-i18n';

export default function () {
  return {
    ...formatDateTranslates,
    ...numDescTranslates,
    ...lc(),
    ...configLabelsTranslates,

    breadcrumbsMain: {
      ru: 'Главная',
      ua: 'Головна'
    },
    toBack: {
      ru: 'Вернуться',
      ua: 'Повернутися'
    },

    toComments: {
      ru: 'К комментариям',
      ua: 'До коментарів'
    },

    developerLinkToSiteTitle: {
      ru: 'Сайт застройщика',
      ua: 'Сайт забудовника'
    },

    expertOpinion: {
      ru: 'мнение эксперта',
      ua: 'думка експерта'
    },

    commentsH2: {
      ru: 'Поделитесь вашим мнением',
      ua: 'Поділіться вашою думкою'
    },

    contentH2: {
      ru: 'Содержание',
      ua: 'Зміст'
    },

    hide: {
      ru: 'скрыть',
      ua: 'сховати'
    },

    show: {
      ru: 'показать',
      ua: 'показати'
    },

    commentsTitle: {
      ru: 'Комментарии посетителей',
      ua: 'Коментарі відвідувачів'
    },

    readAlso: {
      ru: 'Читайте также',
      ua: 'Читайте також'
    },

    searchPlaceholder: {
      ru: 'Поиск',
      ua: 'Пошук'
    },
    searchBtnTitle: {
      ru: 'Найти',
      ua: 'Знайти'
    },

    all: {
      ru: 'Все',
      ua: 'Усі'
    },
    allNews: {
      ru: 'Все новости',
      ua: 'Усі новини'
    },
    rubrics: {
      ru: 'Рубрики',
      ua: 'Рубрики'
    },
    textMinutes: {
      ru: 'мин.',
      ua: 'хв.'
    },
    connectConsult: {
      ru: 'Связаться с консультантом',
      ua: "Зв'язатися з консультантом"
    },
    linkToLcObjects: {
      ru: 'Все планировки',
      ua: 'Все планування'
    },
    toRateArticle: {
      ru: 'Оцените статью',
      ua: 'Оцініть статтю'
    }
  };
}
